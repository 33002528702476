button:disabled {
  cursor: not-allowed !important;
}

.MuiButton-containedPrimary,
.disconnectButton {
  background: #009743 !important;
}
.MuiButton-containedPrimary:hover,
.disconnectButton:hover {
  background: #6cd47f !important;
}

.MuiButton-outlinedPrimary {
  background: #ffffff !important;
  border: 1px solid #009743 !important;
  color: #009743 !important;
}

.MuiButton-outlinedPrimary:hover {
  background: #009743 !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
